import React from 'react'
import Gallery from '@components/Gallery';
import PortofolioLayout from '@components/PortofolioLayout';
import mockImg from "@images/projects/tst/frame-mock.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const screenshots = importAll(require.context("@images/projects/tst/ss", false, /\.(png|jpg|svg)$/));
function importAll(r) {
    return r.keys().map(r);
}


const tstPage = ({ children, location }) => {

    return (
       <PortofolioLayout title="TV Show Tracker">
            <div className="portofolio-project-wrapper ">
                <div className="portofolio-project tst ">
                    <img src={mockImg} alt="tst Screenshots" />
                    <div className="project-section project-intro">
                        <h2>Introduction</h2>
                        <p>
                            A basic TV series tracker. Allows users to see various details about their favorite TV Shows.
                        </p>
                        <div className="project-tags">
                            <span>#AngularJS</span>
                            <span>#Flask</span>
                            <span>#Heroku</span>
                        </div>
                    </div>
                    <div className="project-section project-features">
                        <h2>Achievements</h2>
                        <ul>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Server-side rendering with Angular Universal</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Using the <a href="https://www.tvmaze.com/api" target="_blank">TV Maze API</a></li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Using cron functions to periodically fetch Tv Series data</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Deployed to Digital Ocean using a custom domain </li>
                        </ul>
                    </div>
                    <div className="project-section project-screenshots">
                        <h2>Screenshots</h2>
                        <Gallery images={screenshots} />
                    </div>
                </div>
            </div>
       </PortofolioLayout>
    )
};

export default tstPage;